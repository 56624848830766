import { busynessTypes, paymentTypes } from '~/data/project'

export class FilterBuilder {
  constructor () {
    this.filters = []
  }

  range (id, name, filter, meta, onChange, { from, to, min, max }, filledByDefault = true) {
    const isClear = computed(() => filledByDefault ? filter.value[from] === meta.value[min] && filter.value[to] === meta.value[max] : !filter.value[from] && !filter.value[to])
    return this.custom({
      id,
      name,
      label: computed(() => !isClear.value
        ? (filter.value[from] !== meta.value[min] ? `от ${filter.value[from]}₽ ` : '') + (filter.value[to] !== meta.value[max] ? `до ${filter.value[to]}₽` : '')
        : name),
      clear: () => {
        filter.value[from] = filledByDefault ? meta.value[min] : null
        filter.value[to] = filledByDefault ? meta.value[max] : null

        onChange(from)
        onChange(to)
      },
      isClear: isClear
    })
  }
  price (filter, meta, onChange) {
    return this.range('price', 'Цена', filter, meta, onChange, {
      from: 'rate_from',
      to: 'rate_to',
      min: 'min_rate',
      max: 'max_rate'
    })
  }

  chips (id, name, filter, options = null, defaultId = null) {
    return this.custom({
      id,
      name,
      label: computed(() => {
        const selected = toValue(options)?.filter(s => filter.value[id].includes(s.id))
        return selected?.length ? selected.map(s => s.title ?? s.name).slice(0, 3).join(', ') + (selected.length > 3 ? `, еще ${selected.length - 3}...` : '') : name
      }),
      clear: () => filter.value[id] = defaultId ? [defaultId] : [],
      isClear: computed(() => filter.value[id].length === 0 || (defaultId && filter.value[id].length === 1 && filter.value[id][0] === defaultId))
    })
  }
  skills (...args) { return this.chips('skills', 'Направления', ...args) }
  skillLevels (...args) { return this.chips('skillLevels', 'Квалификация', ...args) }

  select (id, name, filter, options = null) {
    return this.custom({
      id,
      name,
      label: computed(() => (o => o?.title ?? o?.name)(options?.find(o => o.id === filter.value[id])) ?? name),
      clear: () => filter.value[id] = null,
      isClear: computed(() => filter.value[id] === null)
    })
  }
  busyness (filter) { return this.select('busyness', 'Занятость', filter, busynessTypes) }

  custom (filter) {
    this.filters.push(filter)
    return this
  }

  unite (id, name, filters) {
    return this.custom({
      id,
      name,
      label: computed(() => (l => l ? l : name)(filters.filter(f => f.label?.value !== f.name).map(f => f.label?.value?.toLowerCase().trim()).join(', '))),
      clear: () => filters.forEach(f => f.clear?.()),
      isClear: computed(() => filters.every(f => !f.isClear || f.isClear.value))
    })
  }
  payment (filter, meta, onChange) {
    return this.unite(
      'payment',
      'Оплата',
      new FilterBuilder()
        .range('payment_range', 'Оплата', filter, meta, onChange, {
          from: 'payment_from',
          to: 'payment_to',
          min: 'payment_min',
          max: 'payment_max'
        }, false)
        .select('payment_type', 'Формат оплаты', filter, paymentTypes)
        .build()
    )
  }

  build () {
    return this.filters.map(f => Object.assign({
      active: computed(() => f.isClear && !f.isClear.value)
    }, f))
  }
}
